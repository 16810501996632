import { Suspense, lazy, useEffect } from "react";
import ErrorBoundary from "./ErrorBoundary";

import { persistor, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import SuspenseLoader from "./utils/SuspenseLoader";
import MyToast from "./utils/MyToast";

function App() {
  const AppRouter = lazy(() => import("./Routes"));

    /* Handling Net Status & Visible Title */

  // Function to handle visibility change
  const handleVisibilityChange = () => {
    if (document.hidden) {
      document.title = "What Happened Here";
    } else {
      document.title = "What Happened Here";
    }
  };

  useEffect(() => {
    const handleOnline = () => {
      MyToast("s", "Network connection restored!", "top-center");
    };

    const handleOffline = () => {
      MyToast("w", "Network connection lost!", "top-center");
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<SuspenseLoader />}>
          <ErrorBoundary>
            <AppRouter />
          </ErrorBoundary>
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;